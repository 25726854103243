import React from 'react';
import PropTypes from 'prop-types';

import { BulletTilesStyled } from './BulletTilesStyled.js';

export default function BulletTiles({ children }) {
  return (
    <BulletTilesStyled>
        {children}
    </BulletTilesStyled>
  );
}

BulletTiles.propTypes = {
  children: PropTypes.node.isRequired,
};

BulletTiles.defaultProps = {
};
