import styled from '@emotion/styled';
import media from 'utils/mediaqueries'

export const BulletTilesStyled = styled('ul')({
  display: 'flex',
  flexFlow: 'row wrap',
  listStyle: 'none',
  paddingLeft: 0,

  li: {
    margin: 10,
    padding: 35,
    width: 'calc( (100%) - 20px )',
    background: 'white',
    boxShadow: '10px 10px 18px rgba(0,0,0,0.05)',
    borderRadius: 5,
    textAlign: 'center',

    svg: {
      width: 80,
      height: 'auto',

      path: {
        fill: '#005c82'
      }
    },

    span: {
      display: 'block'
    },

    [media('tablet')]: {
      width: 'calc( (100% / 2) - 20px )',
    },

    [media('tabletFluid')]: {
      width: 'calc( (100% / 3) - 20px )',
    },

    [media('desktop')]: {
      width: 'calc( (100% / 5) - 20px )',
    },
  }
},
// props =>
  // props.bg && {
  //   backgroundColor: props.bg,
  // },
)
