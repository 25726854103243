import React from 'react';
import PropTypes from 'prop-types';

import { CardStyled } from './CardStyled.js';

export default function Card({ children }) {
  return (
    <CardStyled>
      {children}  
    </CardStyled>
  );
}

Card.propTypes = {
  children: PropTypes.node.isRequired,
};

Card.defaultProps = {
};
