import React from 'react';

import Layout from 'components/Layout';
import Seo from 'components/Seo';
import Hero from 'components/Hero';
import Section from 'components/Section';
import Column from 'components/Column';
import BulletTiles from 'components/BulletTiles';
import Card from 'components/Card';
import Court from 'components/Court';
import Icon from 'components/Icon';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';

export default function PageVision({ data }) {
  const theme = 'yellow';
  return (
    <Layout theme={theme}>
      <Seo title="Vision" keywords={['']} />
      <Hero
        theme={theme}
        image={data.vision.childImageSharp.fluid}
        titleFirst="unsere"
        titleSecond="Vision"
      />

      <Section>
        <Column noPadding alignItems="center" justifyContent="center">
          <Img
            fluid={data.map.childImageSharp.fluid}
            objectFit="cover"
            fadeIn
            objectPosition="50% 50%"
            alt=""
          />
        </Column>
        <Column alignItems="left" justifyContent="center">
          <h3>
            <span>legendäre Location</span>
          </h3>
          <p>
            Wir wollen den Sport in Köln nach vorne bringen und ein Treffpunkt
            für die Beachvolleyball-Community darstellen. Hierzu brauchen wir
            einen zentralen, gut erreichbaren Ort, der im besten Fall neben den
            Sportflächen zum Verweilen einlädt.
          </p>
          <p>
            Die perfekte Location zu finden ist nicht einfach und doch gibt es
            viele mögliche Optionen, denen wir nachgehen. Gerne sind wir für
            weitere Vorschläge und Angebote offen!
          </p>
        </Column>
      </Section>

      <Section>
        <Column bg="#133443" color="white">
          <Court>
            <div>
              <Icon width="150" height="200" type="indoor" title="indoor" />
            </div>
            <h3 style={{ color: 'white' }}>
              <span>5</span> Indoor-
              <br />
              Felder
            </h3>
          </Court>
        </Column>
        <Column bg="white">
          <Court>
            <div>
              <Icon width="150" height="200" type="outdoor" title="outdoor" />
            </div>
            <h3>
              <span>4</span> Outdoor-
              <br />
              Felder
            </h3>
          </Court>
        </Column>
      </Section>

      <Section wrapReverse>
        <Column alignItems="right" justifyContent="center">
          <h3>
            Lässiger
            <br />
            Leichtbau
          </h3>
          <ul>
            <li>Flexible, erweiterbare, isolierte Leichtbauhalle</li>
            <li>60x30m </li>
            <li>Offene große Tore für Lüftung im Sommer</li>
            <li>Fußbodenheizung und </li>
            <li>
              Transportfähig (wenn die Pachtdauer begrenzt ist, ziehen wir
              einfach um)
            </li>
          </ul>
        </Column>
        <Column noPadding bigger>
          <Img
            fluid={data.halle.childImageSharp.fluid}
            objectFit="cover"
            fadeIn
            objectPosition="50% 50%"
            alt=""
          />
        </Column>
      </Section>

      <Section>
        <Column bigger>
          <Img
            fluid={data.plan.childImageSharp.fluid}
            objectFit="cover"
            fadeIn
            objectPosition="50% 50%"
            alt=""
          />
        </Column>
        <Column alignItems="left" justifyContent="center">
          <h3>
            Rudimentäre
            <br /> Raumplanung
          </h3>
          <p>
            Wir wollen mindestens 5 Indoor-Felder und 4 Outdoor-Felder bauen.
            Dazu brauchen wir mindestens 3330qm.
          </p>
          <p className="hint">
            Das Bild ist nur ein Beispiel, wie es aussehen könnte...
          </p>
        </Column>
      </Section>

      <Section wrapReverse>
        <Column alignItems="right" justifyContent="center">
          <h3>
            Digital,
            <br /> digger
          </h3>
          <p>
            Wir bauen eine state-of-the-art Web-App, mit der du flexibel deinen
            Platz buchen kannst und viele weitere angenehme Funktionen
            antriffst.
          </p>
        </Column>
        <Column noPadding bigger>
          <Img
            fluid={data.digital.childImageSharp.fluid}
            objectFit="cover"
            fadeIn
            objectPosition="50% 50%"
            alt=""
          />
        </Column>
      </Section>

      <Section>
        <Column noPadding bigger>
          <Img
            fluid={data.event.childImageSharp.fluid}
            objectFit="cover"
            fadeIn
            objectPosition="50% 50%"
            alt=""
          />
        </Column>
        <Column alignItems="left" justifyContent="center">
          <h3>
            Fette
            <br /> Feyerei
          </h3>
          <p>
            Wir wollen ganzjährig wettersichere{' '}
            <strong>Events mit Beachflair</strong> in Köln ermöglichen. Ob
            Business-Veranstaltungen, Hochzeiten oder eigene Events.
          </p>
        </Column>
      </Section>

      <Section wrapReverse>
        <Column alignItems="right" justifyContent="center">
          <h3>
            Gute
            <br /> Gastro
          </h3>
          <p>
            Als Café (morgens) und Bar (abends) sollen gemütliche
            Sitzgelegenheiten mit Blick auf die Felder zu einem Kaffee oder
            Drink einladen. Die gesamte Location wird als ein neuer Place-To-be
            in Köln zum Abhängen und Abfeiern verleiten.
          </p>
          <p className="hint">
            Das Bild ist nur ein Beispiel, wie es aussehen könnte...
          </p>
        </Column>
        <Column noPadding bigger>
          <Img
            fluid={data.cafe.childImageSharp.fluid}
            objectFit="cover"
            fadeIn
            objectPosition="50% 50%"
            alt=""
          />
        </Column>
      </Section>

      <Section bg="#ffff91">
        <Column>
          <h3>
            Jecke
            <br /> Jugendförderung
          </h3>
          <p>
            Soziale Verantwortung und Lokale Verbundenheit sind uns wichtig: Wir
            wollen den Nachwuchs fördern und berücksichtigen in unseren
            Überlegungen Möglichkleiten wie Jugend-Förderprogramme, Bau einer
            Kita und die Zusammenarbeit mit Schulen und Hochschulen.
          </p>
        </Column>
      </Section>

      <Section>
        <Column>
          <h3>
            Vielseitige
            <br /> Vision
          </h3>
          <BulletTiles>
            <li>
              <Icon width="80" height="80" type="event" title="event" />
              <span>Business- & Privatevents</span>
            </li>
            <li>
              <Icon width="80" height="80" type="shop" title="shop" />
              <span>Shop</span>
            </li>
            <li>
              <Icon width="80" height="80" type="yoga" title="yoga" />
              <span>Yoga- / Tanzstudio</span>
            </li>
            <li>
              <Icon width="80" height="80" type="fitness" title="fitness" />
              <span>Fitness / Functional Training</span>
            </li>
            <li>
              <Icon width="80" height="80" type="massage" title="massage" />
              <span>Physio-Praxis</span>
            </li>
            <li>
              <Icon width="80" height="80" type="child" title="child" />
              <span>Kita</span>
            </li>
            <li>
              <Icon width="80" height="80" type="birthday" title="birthday" />
              <span>Kindergeburtstage</span>
            </li>
            <li>
              <Icon width="80" height="80" type="movie" title="movie" />
              <span>Beachkino / Public-Viewing</span>
            </li>
            <li>
              <Icon width="80" height="80" type="sauna" title="sauna" />
              <span>Sauna</span>
            </li>
            <li>
              <Icon width="80" height="80" type="jacuzzi" title="jacuzzi" />
              <span>Smoothie-Jacuzzi</span>
            </li>
            <li>
              <Icon width="80" height="80" type="shirt" title="shirt" />
              <span>Richies Teamshirt-Druckerei</span>
            </li>
            <li>
              <Icon width="80" height="80" type="beer" title="beer" />
              <span>Craftbeer-Brauerei</span>
            </li>
            <li>
              <Icon width="80" height="80" type="cam" title="cam" />
              <span>Kameras für Videoanalyse</span>
            </li>
            <li style={{ fontSize: '4em', paddingTop: 60, color: '#005c82' }}>
              ...
            </li>
          </BulletTiles>
        </Column>
      </Section>

      <Section bg="#e7eeff">
        <Column>
          <h3>
            Goldrichtiges
            <br /> Gründerteam
          </h3>
        </Column>
      </Section>
      <Section bg="#e7eeff">
        <Column>
          <div style={{ display: 'flex', flexFlow: 'row wrap' }}>
            <Card>
              <Img fluid={data.tom.childImageSharp.fluid} fadeIn alt="Tom" />
              <h4>Tom Kirchhartz</h4>
              <p>Digital Expert</p>
              <p className="hint">
                Gründer und Inhaber Digitalagentur Dunckelfeld & Bachelor of
                Arts in Media Design
              </p>
            </Card>

            <Card>
              <Img
                fluid={data.robin.childImageSharp.fluid}
                fadeIn
                alt="Robin"
              />
              <h4>Robin Kirchhartz</h4>
              <p>Marketing Expert</p>
              <p className="hint">
                Gründer und Inhaber Seeherz Gbr & Master in Marketing,
                Wirtschaftspsychologie, International Management
              </p>
            </Card>

            {/* <Card>
              <Img fluid={data.timo.childImageSharp.fluid} fadeIn alt="Robin" />
              <h4>Timo Hilker</h4>
              <p>Chief Executive Officer</p>
              <p className="hint">
                Gründer und Inhaber MIRA TV GmbH; Diplom Eventmanagment
              </p>
            </Card> */}

            <Card>
              <Img
                fluid={data.richard.childImageSharp.fluid}
                fadeIn
                alt="Richard"
              />
              <h4>Richard Klein</h4>
              <p>Chief Creative Officer</p>
              <p className="hint">
                Master of ideation and aviation{' '}
                <span role="img" aria-label="aviation">
                  ✈️
                </span>
              </p>
            </Card>
          </div>
        </Column>
      </Section>

      <Section>
        <Column>
          <h3>Lustige Last-Words</h3>
        </Column>
      </Section>
      <Section>
        <Column>
          <blockquote>
            Mein Platz im Auto ist hinten.
            <span>– Elon Musk</span>
          </blockquote>
        </Column>
      </Section>
    </Layout>
  );
}

export const query = graphql`
  query {
    halle: file(relativePath: { eq: "halle.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    map: file(relativePath: { eq: "map.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    plan: file(relativePath: { eq: "plan.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    jacuzzi: file(relativePath: { eq: "jacuzzi.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    event: file(relativePath: { eq: "events.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    digital: file(relativePath: { eq: "digital.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ball: file(relativePath: { eq: "ball.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    vision: file(relativePath: { eq: "vision.jpeg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    youth: file(relativePath: { eq: "youth.jpeg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    cafe: file(relativePath: { eq: "cafe.jpeg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tom: file(relativePath: { eq: "tom.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    robin: file(relativePath: { eq: "robin.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    richard: file(relativePath: { eq: "richard.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    timo: file(relativePath: { eq: "timo.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
