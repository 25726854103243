import styled from '@emotion/styled';
import media from 'utils/mediaqueries'

export const CardStyled = styled('div')({
  margin: 10,
  padding: 35,
  width: 'calc( (100%) - 20px )',
  background: 'white',
  boxShadow: '10px 10px 18px rgba(0,0,0,0.05)',
  borderRadius: 5,
  textAlign: 'center',
  overflow: 'hidden',

  [media('tabletFluid')]: {
    width: 'calc( (100% / 2) - 20px )',
  },

  [media('desktop')]: {
    width: 'calc( (100% / 4) - 20px )',
  },

  '.gatsby-image-wrapper': {
    // borderRadius: 5,
    margin: '-35px -35px 0 -35px',
  },

  h4: {
    color: '#005c82',
  }
},
// props =>
  // props.bg && {
  //   backgroundColor: props.bg,
  // },
)
