import React from 'react';
import PropTypes from 'prop-types';

import { CourtStyled } from './CourtStyled.js';

export default function Court({ children }) {
  return (
    <CourtStyled>
      {children}
    </CourtStyled>
  );
}

Court.propTypes = {
  children: PropTypes.node.isRequired,
};

Court.defaultProps = {
};
