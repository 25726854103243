import styled from '@emotion/styled';
import media from 'utils/mediaqueries'

export const CourtStyled = styled('div')({
  textAlign: 'center',
  paddingTop: 50,
  paddingBottom: 50,

  [media('tabletFluid')]: {
    paddingTop: 80,
    paddingBottom: 80,
  },

  h3: {
    display: 'inline-block',
    width: 'auto',
    color: '#005c82',
    maxWidth: '100%',
    textAlign: 'left',
    fontSize: '1.6em',

    [media('tabletFluid')]: {
      fontSize: '2em',
      marginTop: 80,
    },

    span: {
      fontSize: '1.8em',
      display: 'block',
      lineHeight: '1em',

      [media('tabletFluid')]: {
        fontSize: '1.5em',
        lineHeight: '1.3em',
      },
    },
  },

  svg: {
    width: 120,

    [media('tabletFluid')]: {
      width: 150,
    },

    path: {
      fill: 'black',
    },
    '.yellow': {
      fill: '#ffe855'
    },
    '.white': {
      fill: 'white'
    }
  }
},
// props =>
  // props.bg && {
  //   backgroundColor: props.bg,
  // },
)
